import { conformToMask } from 'react-text-mask'
import qs from 'qs'
import { getRoot } from 'mobx-state-tree'

import { LocationModelBase } from './LocationModel.base'
import { phoneNumberMask } from '../utils'
import { YAPI_PARTNER_ID } from '../constants'
/* A graphql query fragment builders for LocationModel */
export {
  selectFromLocation,
  locationModelPrimitives,
  LocationModelSelector,
} from './LocationModel.base'

/**
 * LocationModel
 *
 * A Location
 */
export const LocationModel = LocationModelBase.views(self => ({
  get root() {
    return getRoot(self)
  },
  get nameWithNumber() {
    if (self.name) {
      return `${self.name} - ${self.formattedPhoneNumber}`
    }
    return `${self.street} - ${self.formattedPhoneNumber}`
  },
  get formattedPhoneNumber() {
    return conformToMask(self.phoneNumber, phoneNumberMask, { guide: false })
      .conformedValue
  },
  get formattedAddress() {
    return `${self.street} ${self.city}, ${self.state} ${self.zip}`
  },
  get yapiBookingUrlWithPatientData() {
    const patientDataAsParams = qs.stringify(
      self.root.patientInput.yapiUrlParams,
      { encode: true }
    )
    const yapiPartnerId = `&partnerId=${YAPI_PARTNER_ID}`
    const appointmentRequestId = self.root.appointmentRequestId ? `&appointmentRequestId=${self.root.appointmentRequestId}` : ''
    const partnerName = self.root.partner ? `&partnerName=${self.root.partner.name}` : ''
    const url = `${self.yapiBookingUrl}&${patientDataAsParams}${appointmentRequestId}${partnerName}${yapiPartnerId}`
    return `${url}`
  },
  get activeProviders() {
    if (self.root.isSdk && self.providers.length) {
      return self.providers.filter(provider => provider.chewsiIsActive)
    }
    return self.providers
  }
}))
