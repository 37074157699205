/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { PartnerModel } from "./PartnerModel"
import { PartnerModelSelector } from "./PartnerModel.base"
import { ProviderModel } from "./ProviderModel"
import { ProviderModelSelector } from "./ProviderModel.base"


/**
 * AppointmentRequestBase
 * auto generated base class for the model AppointmentRequestModel.
 *
 * An Appointment Request
 */
export const AppointmentRequestModelBase = ModelBase
  .named('AppointmentRequest')
  .props({
    __typename: types.optional(types.literal("AppointmentRequest"), "AppointmentRequest"),
    id: types.identifier,
    insertedAt: types.union(types.undefined, types.null, types.string),
    isEmergency: types.union(types.undefined, types.null, types.boolean),
    location: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => LocationModel))),
    partner: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PartnerModel))),
    provider: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => ProviderModel))),
    referrer: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class AppointmentRequestModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get insertedAt() { return this.__attr(`insertedAt`) }
  get isEmergency() { return this.__attr(`isEmergency`) }
  get referrer() { return this.__attr(`referrer`) }
  location(builder) { return this.__child(`location`, LocationModelSelector, builder) }
  partner(builder) { return this.__child(`partner`, PartnerModelSelector, builder) }
  provider(builder) { return this.__child(`provider`, ProviderModelSelector, builder) }
}
export function selectFromAppointmentRequest() {
  return new AppointmentRequestModelSelector()
}

export const appointmentRequestModelPrimitives = selectFromAppointmentRequest().insertedAt.isEmergency.referrer
