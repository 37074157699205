import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '../models/reactUtils'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  appointmentType: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1)
  },
}))

export const AppointmentSlot = ({ appointment }) => {
  const classes = useStyles()
  const { store } = useQuery()
  return (
    <Button
      className={classes.button}
      variant="outlined"
      color="default"
      onClick={() => store.setSelectedAppointment(appointment.id)}
    >
      <Typography className={classes.appointmentType}>
        {appointment.appointmentType && `${appointment.appointmentType.name}`}
      </Typography>
      {appointment.provider.name} at{' '}
      {moment
        .tz(appointment.start, 'UTC')
        .tz(appointment.location.timezone)
        .format('h:mm A z')}
    </Button>
  )
}

AppointmentSlot.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string,
    start: PropTypes.string,
    provider: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  }),
}

export default observer(AppointmentSlot)
