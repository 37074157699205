/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AppointmentModel } from "./AppointmentModel"
import { AppointmentModelSelector } from "./AppointmentModel.base"
import { AppointmentRequestModel } from "./AppointmentRequestModel"
import { AppointmentRequestModelSelector } from "./AppointmentRequestModel.base"


/**
 * RootMutationTypeBase
 * auto generated base class for the model RootMutationTypeModel.
 */
export const RootMutationTypeModelBase = ModelBase
  .named('RootMutationType')
  .props({
    __typename: types.optional(types.literal("RootMutationType"), "RootMutationType"),
    /** Book an appointment */
    bookAppointment: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => AppointmentModel))),
    /** Create an appointment */
    createAppointment: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => AppointmentModel))),
    /** Request an appointment */
    requestAppointment: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => AppointmentRequestModel))),
    /** Update an appointment */
    updateAppointment: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => AppointmentModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class RootMutationTypeModelSelector extends QueryBuilder {
  bookAppointment(builder) { return this.__child(`bookAppointment`, AppointmentModelSelector, builder) }
  createAppointment(builder) { return this.__child(`createAppointment`, AppointmentModelSelector, builder) }
  requestAppointment(builder) { return this.__child(`requestAppointment`, AppointmentRequestModelSelector, builder) }
  updateAppointment(builder) { return this.__child(`updateAppointment`, AppointmentModelSelector, builder) }
}
export function selectFromRootMutationType() {
  return new RootMutationTypeModelSelector()
}

export const rootMutationTypeModelPrimitives = selectFromRootMutationType()
