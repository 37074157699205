import React from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import ErrorIcon from '@material-ui/icons/Error'
import { useQuery } from '../models/reactUtils'

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  errorIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const Alert = () => {
  const classes = useStyles()
  const { store } = useQuery()
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={!!store.errorMessage}
      autoHideDuration={6000}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      onClose={store.resetErrorMessage}
    >
      <SnackbarContent
        className={classes.error}
        message={
          <span id="message-id" className={classes.message}>
            <ErrorIcon className={classes.errorIcon} /> {store.errorMessage}
          </span>
        }
      />
    </Snackbar>
  )
}

export default observer(Alert)
