import gql from 'graphql-tag'
export * from './practice'

export const AVAILABLE_APPOINTMENTS = gql`
  query AppointmentsQuery($id: ID!) {
    location(id: $id) {
      id
      name
      __typename
      availableAppointments {
        __typename
        id
        bookedAt
        end
        start
        provider {
          __typename
          id
          name
        }
        location {
          __typename
          id
        }
        appointmentType {
          __typename
          id
          name
        }
      }
    }
  }
`
