import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'

import AppointmentSlot from './AppointmentSlot'

const wrapper = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  borderBottom: '1px solid #d8d8d8',
}

const container = {
  display: 'flex',
  flexFlow: 'row wrap',
  width: '100%',
}

export const AppointmentGroup = ({ appointmentGroup }) => {
  const { date, slots } = appointmentGroup
  return (
    <div style={wrapper}>
      <Typography variant="subtitle1">
        {moment(date).calendar(null, {
          sameDay() {
            return `[Today (${this.format('MMMM Do YYYY')})]`
          },
          nextDay() {
            return `[Tomorrow (${this.format('MMMM Do YYYY')})]`
          },
          nextWeek() {
            return `dddd [(${this.format('MMMM Do YYYY')})]`
          },
          lastDay: '[Yesterday]',
          lastWeek: '[Last] dddd',
          sameElse: 'dddd MMMM Do YYYY',
        })}
      </Typography>
      <div style={container}>
        {slots &&
          slots.length > 0 &&
          slots.map(slot => (
            <AppointmentSlot key={slot.id} appointment={slot} />
          ))}
      </div>
    </div>
  )
}

AppointmentGroup.propTypes = {
  appointmentGroup: PropTypes.shape({
    date: PropTypes.string,
    slots: PropTypes.array,
  }),
}

export default observer(AppointmentGroup)
