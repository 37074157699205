import { when, autorun, toJS } from 'mobx'
import { types, getEnv, onSnapshot, applySnapshot, flow } from 'mobx-state-tree'
import { conformToMask } from 'react-text-mask'

import { RootStoreBase } from './RootStore.base'
import { AppointmentModel } from './AppointmentModel'
import {
  byDate,
  toGroupedByDate,
  normalizedNumberMask,
  toOpenAppointments,
} from '../utils'
import * as Analytics from '../utils/analytics'
import { AVAILABLE_APPOINTMENTS, PRACTICE } from '../queries'

import { PatientModel } from './PatientModel'

export const RootStore = RootStoreBase.named('RootWidgetStore')
  .props({
    isOpen: false,
    practiceId: types.maybeNull(types.string),
    locationId: types.maybeNull(types.string),
    partnerId: types.maybeNull(types.string),
    findProvider: false,
    selectedLocationId: types.maybeNull(types.string),
    selectedAppointment: types.maybeNull(types.string),
    requestAppointment: false,
    sortedAppointments: types.optional(
      types.array(types.reference(AppointmentModel)),
      []
    ),
    socketConnected: false,
    errorMessage: '',
    submittingPatientForm: false,
    patientInput: types.maybeNull(PatientModel),
    partnerMemberId: types.maybeNull(types.string),
    isSdk: types.maybeNull(types.boolean)
  })
  .views(self => ({
    get hasMultipleLocations() {
      return self.locations.size > 1
    },
    get groupedAndSortedAppointments() {
      if (self.sortedAppointments.length) {
        return self.sortedAppointments
          .toJS()
          .filter(toOpenAppointments)
          .sort(byDate)
          .reduce(toGroupedByDate, [])
      }
      return []
    },
    get practice() {
      return self.practices.get(self.practiceId)
    },
    get appointment() {
      return self.appointments.get(self.selectedAppointment)
    },
    get selectedLocation() {
      if (self.selectedLocationId) {
        return self.locations.get(self.selectedLocationId)
      }
      return null
    },
    get trackers() {
      if (self.partners.size > 0) {
        return Array.from(self.partners.values()).map(partner => partner.trackerName)
      }
      return []
    },
    get patientData() {
      if (self.patientInput !== null) {
        const patientData = toJS(self.patientInput)
        delete patientData['__typename']
        return patientData
      } else {
        return null
      }
    },
    get partner() {
      if (self.partnerId) {
        return self.partners.get(self.partnerId)
      }
      return null
    },
    get appointmentRequestId() {
      return self.appointmentRequests.values().next().value && self.appointmentRequests.values().next().value.id
    },
    get appointmentSelectedForBooking() {
      return self.appointments.get(self.selectedAppointment)
    },
  }))
  .volatile(() => ({
    channelInstance: null,
    locationPageViewReaction: null,
  }))
  .actions(self => ({
    afterCreate() {
      onSnapshot(self, () => {
        when(
          () =>
            !self.locationId &&
            !self.selectedLocation &&
            self.locations.size === 1,
          () => {
            self.setSelectedLocation(
              self.locations.entries().next().value[1].id
            )
          }
        )
      })
      // Notify the store when the socket connects
      getEnv(self).socket.onOpen(self.socketOnOpen)
      getEnv(self).socket.onClose(self.socketOnClosed)
      autorun(
        () => (self.selectedLocation && self.selectedLocation.yapiIntegrationEnabled ? self.openIntegrationForm() : () => { })
      )
    },
    initialize({ practiceId, partnerId, locationId, partnerMemberId, isSdk }) {
      if (practiceId) {
        self.practiceId = practiceId
      }
      if (locationId) {
        self.locationId = locationId
        self.selectedLocationId = locationId
      }
      if (partnerId) {
        self.partnerId = partnerId
      }
      if (partnerMemberId) {
        self.partnerMemberId = partnerMemberId
      }
      if (isSdk) {
        self.isSdk = isSdk
      }
    },
    resetSortedAppointments() {
      self.sortedAppointments = []
    },
    closeModal() {
      if (self.socketConnected) {
        self.socketOnClosed()
      }
      getEnv(self).socket.disconnect()
      getEnv(self).history.replace('/')
      Analytics.widgetClosedEvent(self.practiceId, self.trackers)
      self.resetToInitialState()

      window.parent.postMessage('widget close', '*')
    },
    openModal() {
      Analytics.widgetOpenEvent(self.practiceId, self.trackers)
      self.selectedAppointment = null
      self.requestAppointment = false
      self.isOpen = true
      getEnv(self).socket.connect()
      window.parent.postMessage('widget open', '*')
    },
    setRequestAppointment() {
      self.requestAppointment = true
      self.selectedAppointment = null
      getEnv(self).history.push('/form')
      Analytics.appointmentRequestPageView(self.selectedLocationId, self.trackers)
      Analytics.appointmentRequestStartedEvent(self.selectedLocationId, self.trackers)
    },
    resetRequestAppointment() {
      self.requestAppointment = false
      getEnv(self).history.replace('/')
      Analytics.locationSchedulePageView(self.selectedLocationId, self.trackers)
      Analytics.appointmentRequestCancelledEvent(self.selectedLocationId, self.trackers)
    },
    setSelectedAppointment(id) {
      self.selectedAppointment = id
      self.requestAppointment = false
      getEnv(self).history.push('/form')
      Analytics.appointmentBookingPageView(self.selectedLocationId, self.trackers)
      Analytics.appointmentBookingStartedEvent(self.selectedLocationId, self.trackers)
    },
    resetSelectedAppointment() {
      self.selectedAppointment = null
      getEnv(self).history.replace('/')
      Analytics.locationSchedulePageView(self.selectedLocationId, self.trackers)
      Analytics.appointmentBookingCancelledEvent(self.selectedLocationId, self.trackers)
    },
    setSelectedLocation(id) {
      self.selectedLocationId = id
      Analytics.locationSchedulePageView(id, self.trackers)
      Analytics.locationSelectedEvent(id, self.trackers)
    },
    resetSelectedLocation() {
      self.selectedLocationId = null
    },
    redirectToConfirm() {
      getEnv(self).history.push('/confirm')
    },
    resetErrorMessage() {
      self.errorMessage = ''
    },
    setErrorMessage(message) {
      self.errorMessage = message
    },
    toggleSubmitting(value) {
      self.submittingPatientForm = value
    },
    socketOnOpen() {
      if (self.socketConnected) {
        return
      }
      self.socketConnected = true
      // Join the channel and subscribe to appointment_booked events
      self.channelInstance = getEnv(self).socket.channel(
        `practice:${self.practiceId}`
      )
      self.channelInstance.join()
      self.channelInstance.on('appointment_booked', self.setAppointmentAsBooked)
      self.channelInstance.on(
        'appointment_created',
        self.receiveCreatedAppointment
      )
      self.channelInstance.on(
        'appointment_deleted',
        self.receiveDeletedAppointment
      )
    },
    socketOnClosed() {
      if (self.socketConnected && self.channelInstance) {
        self.socketConnected = false
        self.channelInstance.off('appointment_booked')
        self.channelInstance.off('appointment_created')
        self.channelInstance.off('appointment_deleted')
        self.channelInstance.leave()
      }
    },
    setAppointmentAsBooked(bookedAppointment) {
      const {
        id,
        location,
        provider_id,
        booked_at,
        ...rest
      } = bookedAppointment

      const claimedAppointment = AppointmentModel.create({
        id,
        provider: provider_id,
        location: location.id,
        bookedAt: booked_at,
        ...rest,
      })
      self.sortedAppointments = self.sortedAppointments.map(openAppointment => {
        if (openAppointment.id === id) {
          return claimedAppointment
        }
        return openAppointment
      })
      self.appointments.set(id, claimedAppointment)

      if (!self.submittingPatientForm && self.selectedAppointment === id) {
        self.errorMessage =
          'Sorry this appointment has been taken or removed. Please select another appointment time.'
        self.selectedAppointment = null
        self.openLocations()
      }
    },
    receiveCreatedAppointment(createdAppointment) {
      const { id, location, provider_id, ...rest } = createdAppointment
      const appointment = AppointmentModel.create({
        id,
        provider: provider_id,
        location: location.id,
        ...rest,
      })
      self.appointments.set(id, appointment)
      if (location.id === self.selectedLocationId) {
        self.sortedAppointments.push(appointment)
      }
    },
    receiveDeletedAppointment(deletedAppointment) {
      const { id } = deletedAppointment
      if (id === self.selectedAppointment) {
        self.selectedAppointment = null
        self.errorMessage =
          'Sorry this appointments has been taken or removed. Please select another appointment time.'
      }
      self.sortedAppointments = self.sortedAppointments.filter(
        appointment => appointment.id !== id
      )
      self.appointments.delete(id)
      self.openLocations()
    },
    fetchAppointmentsForLocation: flow(function* fetchAppointmentsForLocation(
      id
    ) {
      try {
        const data = yield self.query(
          AVAILABLE_APPOINTMENTS,
          { id },
          { fetchPolicy: 'network-only' }
        )
        self.sortedAppointments = data.location.availableAppointments.toJS()
      } catch (error) {
        console.error(error)
      }
    }),
    submitRequestAppointment(values) {
      const { providerId, isEmergency, yapiRequest, ...patientData } = values
      const { phone, ...patient } = patientData
      const normalizedPhoneNumber = conformToMask(phone, normalizedNumberMask, {
        guide: false,
      }).conformedValue

      const variables = {
        locationId: self.selectedLocationId,
        isEmergency: Boolean(isEmergency),
        patient: { phone: normalizedPhoneNumber, partnerMemberId: self.partnerMemberId, ...patient },
        partnerId: self.partnerId,
        providerId: providerId || null,
        yapiRequest: yapiRequest || null,
        referrer: document.referrer,
      }

      const optimisticUpdate = () => {
        Analytics.appointmentRequestCompletedPageView(self.selectedLocationId, self.trackers)
        Analytics.appointmentRequestCompletedEvent(self.selectedLocationId, self.trackers)
      }
      return self.mutateRequestAppointment(variables, undefined, optimisticUpdate)
    },
    submitBookAppointment(values) {
      const { phone, ...patient } = values
      const normalizedPhoneNumber = conformToMask(phone, normalizedNumberMask, {
        guide: false,
      }).conformedValue

      const variables = {
        appointmentId: self.selectedAppointment,
        partnerId: self.partnerId,
        providerId: self.appointmentSelectedForBooking.provider.id,
        locationId: self.appointmentSelectedForBooking.location.id,
        patient: { phone: normalizedPhoneNumber, partnerMemberId: self.partnerMemberId, ...patient },
        referrer: document.referrer,
      }
      const optimisticUpdate = () => {
        Analytics.appointmentBookingCompletedPageView(self.selectedLocationId, self.trackers)
        Analytics.appointmentBookingCompletedEvent(self.selectedLocationId, self.trackers)
      }
      return self.mutateBookAppointment(variables, undefined, optimisticUpdate)

    },
    submitIntegrationRequest(values) {
      self.setPatientData(values)
      if (self.selectedLocation.yapiIntegrationEnabled) {
        return self.submitRequestAppointment({ ...values, yapiRequest: true })
      }

      return self.submitRequestAppointment(values)
    },
    resetToInitialState() {
      applySnapshot(self, {})
    },
    fetchCurrentPractice() {
      if (self.practiceId !== null) {
        return self.fetchPractice(self.practiceId)
      }
      return Promise.reject('There is an issue with widget configuration')
    },
    fetchPractice(practiceId) {
      return self.query(PRACTICE, { id: practiceId })
    },
    setPatientData(values) {
      self.patientInput = PatientModel.create(values)
    },
    openLocations() {
      getEnv(self).history.push('/')
    },
    openIntegrationForm() {
      getEnv(self).history.push('/integration/form')
    },
    openIntegrationFrame() {
      getEnv(self).history.push('/integration/frame')
    },
  }))
