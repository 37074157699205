if (!window.Countly || !Array.isArray(window.Countly.q)) {
  window.Countly = { q: [] }
}

// Define the event tracking function
function trackEvent(eventName, eventData) {
  if (window.Countly.q) {
    window.Countly.q.push(['add_event', { key: eventName, segmentation: eventData }])
  }
}

export const widgetOpenEvent = id => {
  trackEvent('Widget Opened', { practiceId: id })
}

export const widgetClosedEvent = id => {
  trackEvent('Widget Closed', { practiceId: id })
}

export const locationSelectedEvent = id => {
  trackEvent('Location Selected', { locationId: id })
}

export const appointmentRequestStartedEvent = id => {
  trackEvent('Appointment Request Started', { locationId: id })
}

export const appointmentRequestCancelledEvent = id => {
  trackEvent('Appointment Request Cancelled', { locationId: id })
}
export const appointmentRequestCompletedEvent = id => {
  trackEvent('Appointment Request Completed', { locationId: id })
}

export const appointmentBookingStartedEvent = id => {
  trackEvent('ScheduleWidget Booking Started', { locationId: id })
}

export const appointmentBookingCancelledEvent = id => {
  trackEvent('ScheduleWidget Booking Cancelled', { locationId: id })
}

export const appointmentBookingCompletedEvent = id => {
  trackEvent('ScheduleWidget Booking Completed', { locationId: id })
}

export const locationSchedulePageView = id => {
  const page_path = `/locations/${id}/schedule`
  window.Countly.q.push(['track_pageview', page_path])
}

export const appointmentRequestPageView = id => {
  const page_path = `/locations/${id}/form`
  window.Countly.q.push(['track_pageview', page_path])
}

export const appointmentBookingPageView = id => {
  const page_path = `/locations/${id}/form`
  window.Countly.q.push(['track_pageview', page_path])
}

export const appointmentRequestCompletedPageView = id => {
  const page_path = `/locations/${id}/confirm_request`
  window.Countly.q.push(['track_pageview', page_path])
}



export const appointmentBookingCompletedPageView = id => {
  const page_path = `/locations/${id}/confirm_booking`
  window.Countly.q.push(['track_pageview', page_path])
}