import React from 'react'
import { observer } from 'mobx-react-lite'
import { Router, Switch, Route } from 'react-router-dom'

import Schedule from './Schedule'
import PatientForm from './PatientForm'
import Confirm from './Confirm'
import Alert from './Alert'
import IntegrationForm from './IntegrationForm'
import Loading from './Loading'
import Error from './Error'
import IntegrationFrame from './IntegrationFrame'

import { useQuery } from '../models/reactUtils'
import PropTypes from 'prop-types'

const WidgetRoutes = ({ history, toggleModal, staticPage }) => {
  const { error, loading } = useQuery(queryStore => queryStore.fetchCurrentPractice())
  
  if (error) {
    return (
      <Error message="Sorry, there was an issue loading practice information" />
    )
  }

  if (loading) {
    return (
      <Loading
        id="PracticeInformationLoadingMessage"
        message="Loading practice information..."
      />
    )
  }

  return (
    <Router history={history}>
      <Alert />
      <Switch>
        <Route exact path="/" component={Schedule} />
        <Route path="/integration/form" component={IntegrationForm} />
        <Route path="/integration/frame" component={IntegrationFrame} />
        <Route path="/form" component={PatientForm} />
        <Route
          path="/confirm"
          render={props => (
            <Confirm
              {...props}
              toggleModalCallback={toggleModal}
              staticPage={staticPage}
            />
          )}
        />
      </Switch>
    </Router>
  )
}
WidgetRoutes.defaultProps = {
  staticPage: false
}

WidgetRoutes.propTypes = {
  history: PropTypes.object,
  toggleModal: PropTypes.func,
  staticPage: PropTypes.bool,
}

export default observer(WidgetRoutes)
