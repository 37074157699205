import React from 'react'
import { observer } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Formik, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'

import { useQuery } from '../models/reactUtils'
import AppointmentDetails from './AppointmentDetails'
import PhoneNumberField from './PhoneNumberField'
import * as Utils from '../utils'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  errorIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  email: {
    color: 'white'
  }
}))

const IntegrationFormSchema = Yup.object().shape({
  firstName: Yup.string().max(15, 'First name should not exceed 15 characters').required('First name is required'),
  lastName: Yup.string().max(20, 'Last name should not exceed 20 characters').required('Last name is required'),
  email: Yup.string().email().required('Email address is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .min(14, 'Phone number must be at least 10 digits'),
})

export const IntegrationForm = () => {
  const { store, setQuery } = useQuery() // eslint-disable-line function-paren-newline
  const classes = useStyles()

  return (
    <React.Fragment>
      {(!store.locationId && store.hasMultipleLocations) && 
      (<Grid justify="flex-start" container>
        <Button
          onClick={() => {
            store.openLocations()
            store.resetLocation() 
          }}
          variant="text"
        >
          <KeyboardArrowLeft /> Back
        </Button>
      </Grid>)}
      <AppointmentDetails />
      <Divider />
      <Typography
        id="ContactInfoHeader"
        gutterBottom
        align="center"
        variant="h6"
      >
        Contact info
      </Typography>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          comment: '',
        }}
        onSubmit={
          async (values, { setSubmitting, setErrors }) => {
          try {
            store.toggleSubmitting(true)
            await setQuery(store.submitIntegrationRequest(values))
            store.openIntegrationFrame()
          } catch (queryError) {
            setSubmitting(false)
            store.toggleSubmitting(false)
            Utils.mapErrorsToFields(queryError, store, setErrors)
          }
        }
      }
        validationSchema={IntegrationFormSchema}
      >
        {({ values, isSubmitting, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit} data-testid="form">
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  name="firstName"
                  variant="outlined"
                  required
                  id="firstName"
                  label="First Name"
                  autoFocus
                  fullWidth
                  value={values.firstName}
                  onChange={handleChange}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="lastName"
                  variant="outlined"
                  required
                  id="lastName"
                  label="Last Name"
                  fullWidth
                  value={values.lastName}
                  onChange={handleChange}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  label="Mobile number"
                  id="phone"
                  name="phone"
                  type="tel"
                  value={values.phone}
                  onChange={handleChange}
                  component={PhoneNumberField}
                />
              </Grid>
            </Grid>
            <Button
              id="SubmitFormButton"
              data-testid="SubmitFormButton"
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isValid || isSubmitting}
            >
              Continue
            </Button>
          </form>
        )}
      </Formik>
    </React.Fragment>
  )
}

IntegrationForm.propTypes = {}

export default observer(IntegrationForm)
