import React from 'react'
import { observer } from 'mobx-react-lite'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

import AppointmentList from './AppointmentList'
import LocationSelection from './LocationSelection'
import LocationInfo from './LocationInfo'
import { useQuery } from '../models/reactUtils'

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(1),
  },
}))

export const Schedule = () => {
  const classes = useStyles()
  const { store } = useQuery()

  return (store.practiceId && store.practice) && (
    <Grid className={classes.container}>
      <Typography variant="h5">{store.practice.name}</Typography>
      {!store.locationId && store.hasMultipleLocations ? (
        <LocationSelection />
      ) : (
          <LocationInfo />
        )}
      {store.selectedLocationId && (
        <Grid>
          <AppointmentList />
        </Grid>
      )}
    </Grid>
  )
}

export default observer(Schedule)
