import { createMuiTheme } from '@material-ui/core/styles'

import colors from './assets/colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: 'white',
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    useNextVariants: true,
  },
})

export default theme
