import React from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from '../models'


const IntegrationFrame = () =>{
  const { store } = useQuery()
  return (<iframe
    title="YAPI Booking Page"
    style={{ border: 0, height: '84vh'}} 
    src={store.selectedLocation.yapiBookingUrlWithPatientData} 
  />)
}

export default observer(IntegrationFrame)
