import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react-lite'
import { useQuery } from '../models/reactUtils'

const LocationSelection = () => {
  const { store } = useQuery()
  return (
    <React.Fragment>
      <Typography variant="caption">
        Select a location to see available appointments
      </Typography>
      <TextField
        select
        fullWidth
        label={
          store.selectedLocationId ? 'Selected location' : 'Select a location'
        }
        value={store.selectedLocationId || ''}
        onChange={({ target: { value: id } }) => store.setSelectedLocation(id)}
        margin="normal"
        variant="outlined"
        id="LocationSelectionField"
        InputLabelProps={{ shrink: !!store.selectedLocationId }}
      >
        {Array.from(store.locations).map(([id, location]) => {
          return (
            <MenuItem key={id} value={id}>
              {location.nameWithNumber}
            </MenuItem>
          )
        })}
      </TextField>
    </React.Fragment>
  )
}

export default observer(LocationSelection)
