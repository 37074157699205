import moment from 'moment-timezone'
import * as Sentry from '@sentry/browser'

const ROOT_URL = `https://${process.env.REACT_APP_ROOT_URL}`
export const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const normalizedNumberMask = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const byDate = (leftHandSide, rightHandSide) => {
  if (
    moment.tz(leftHandSide.start, 'UTC').format() <
    moment.tz(rightHandSide.start, 'UTC').format()
  ) {
    return -1
  }
  if (
    moment.tz(leftHandSide.start, 'UTC').format() >
    moment.tz(rightHandSide.start, 'UTC').format()
  ) {
    return 1
  }
  return 0
}

export const toGroupedByDate = (collection, appointment) => {
  const group = collection.find(({ date }) =>
    moment(date).isSame(moment(appointment.start), 'day')
  )

  if (group) {
    group.slots.push(appointment)
  } else {
    collection.push({
      date: appointment.start,
      slots: [].concat([appointment]),
    })
  }
  return collection
}

export const toCurrentDate = item => moment(item.start).isAfter(moment().utc())

export const toOpenAppointments = item => !item.bookedAt

export const buildAssetUrl = assetFile => `${ROOT_URL}/${assetFile}`

export const isDev = process.env.NODE_ENV === 'development'

export const logger = {
  error: error => {
    if (!isDev) {
      return Sentry.captureException(error)
    }
    return console.log({ error }) // eslint-disable-line
  },
  log: (kind, msg) => {
    if (isDev) {
      return console.log(`socket: ${kind}, ${msg}`) // eslint-disable-line
    }
    return null
  },
}

export const mapErrorsToFields = (queryError, store, setErrors) => {
  // Generic Error
  if (queryError.message) {
    store.setErrorMessage('Oops! Something went wrong. Our team has been notified of the issue. Please try again later.')
  }
  if (
    queryError &&
    queryError.response &&
    queryError.response.errors
  ) {
    store.toggleSubmitting(false)
    queryError.response.errors.map(e => {
      // Errors are for fields
      if (Array.isArray(e.errors)) {
        e.errors.map(err =>
          setErrors({ [err.field]: err.message })
        )
      }
      return null
    })
  }
}
