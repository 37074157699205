import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import images from '../assets/images'
import { DEFAULT_CTA_TEXT } from '../constants'

const useStyles = makeStyles(theme => ({
  typography: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    lineHeight: 1.2,
  },
  ctaButton: {
    border: '1px solid white',
    maxWidth: 290,
  },
}))

const CTA = ({ isDark, isOpen, inline, onClick, ctaText, position }) => {
  const classes = useStyles()
  const isRight = position === 'right'

  const buttonStyles = () => {
    const style = {}
    if (isOpen) {
      style.position = 'fixed'
      style.bottom = 30
      style.left = 30
    }
    if (isOpen && isRight) {
      style.position = 'fixed'
      style.left = 'auto'
      style.right = 30 + 14
    }
    if (inline) {
      return undefined
    }
    return style
  }

  return (
    <Fab
      className={classes.ctaButton}
      color={isDark ? 'secondary' : 'primary'}
      variant="extended"
      onClick={onClick}
      size="large"
      style={buttonStyles()}
    >
      <Avatar alt="ScheduleWidget logo" src={images.icon} />
      <Typography variant="button" className={classes.typography}>
        {ctaText || DEFAULT_CTA_TEXT}
      </Typography>
    </Fab>
  )
}
CTA.propTypes = {
  onClick: PropTypes.func,
  isDark: PropTypes.bool,
  isOpen: PropTypes.bool,
  ctaText: PropTypes.string,
  inline: PropTypes.bool,
  position: PropTypes.string,
}
export default CTA
