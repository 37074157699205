import React from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'

import CTA from './CTA'
import ErrorBoundary from './ErrorBoundary'
import WidgetModal from './WidgetModal'
import theme from '../theme'

export const App = ({
  isDark,
  ctaText,
  position,
  practiceId,
  partnerId,
  partnerMemberId,
  isExternal,
}) => {
  const [isOpen, toggleModal] = React.useState(false)
  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={theme}>
        <div>
          {!isExternal && (
            <CTA
              isDark={isDark}
              ctaText={ctaText}
              onClick={() => toggleModal(!isOpen)}
              isOpen={isOpen}
              position={position}
            />
          )}
          <WidgetModal
            practiceId={practiceId}
            isOpen={isOpen}
            toggleModalCallback={toggleModal}
            partnerId={partnerId}
            partnerMemberId={partnerMemberId}
            isExternal={isExternal}
          />
        </div>
      </MuiThemeProvider>
    </ErrorBoundary>
  )
}

App.propTypes = {
  isDark: PropTypes.bool,
  ctaText: PropTypes.string,
  partnerId: PropTypes.string,
  position: PropTypes.string,
  practiceId: PropTypes.string,
  partnerMemberId: PropTypes.string,
  isExternal: PropTypes.bool,
}
export default App
