import React from 'react'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react-lite'
import { useQuery } from '../models/reactUtils'

export const LocationInfo = () => {
  const { store } = useQuery()
  return (
    store &&
    store.selectedLocation && (
      <React.Fragment>
        <Typography>
          <strong>Location:</strong> {store.selectedLocation.formattedAddress}
        </Typography>
        <Typography>
          <strong>Phone:</strong> {store.selectedLocation.formattedPhoneNumber}
        </Typography>
      </React.Fragment>
    )
  )
}

LocationInfo.propTypes = {}

export default observer(LocationInfo)
