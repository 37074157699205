import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { createHttpClient } from 'mst-gql'
import { getEnv } from 'mobx-state-tree'
import { Socket } from 'phoenix-socket'
import { createMemoryHistory } from 'history'
import Dialog from '@material-ui/core/Dialog'
import { Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles'

import DialogTitle from './DialogTitle'
import DialogContent from './DialogContent'
import DialogActions from './DialogActions'
import WidgetRoutes from './WidgetRoutes'

import { RootStore, StoreContext } from '../models'
import images from '../assets/images'
import { logger, isDev } from '../utils'
import { API } from '../config'
import theme from '../theme'

import {
  PRIVACY_POLICY_URL,
  TERMS_CONDITIONS_URL
} from '../constants'

const socket = new Socket(API.socket, { logger: logger.socketlog })
socket.connect()

const history = createMemoryHistory({
  initialEntries: ['/', '/form', '/confirm'],
  initialIndex: 0,
})
const widgetStore = RootStore.create(
  {},
  {
    gqlHttpClient: createHttpClient(API.graphApi),
    socket,
    logger,
    history,
  }
)

// Initialize developer tools
if (isDev) {
  window.widgetStore = widgetStore
  window.router = history
  window.getEnv = getEnv
}

const useStyles = makeStyles(currentTheme => ({
  paper: {
    [currentTheme.breakpoints.up('sm')]: {
      minWidth: 600,
    },
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}))
const WidgetModal = ({
  practiceId,
  partnerId,
  locationId,
  isOpen,
  toggleModalCallback,
  staticPage,
  partnerMemberId,
  rootStore,
  isExternal
}) => {
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  React.useEffect(() => {
    rootStore.initialize({ practiceId, locationId, partnerId, partnerMemberId, isSdk: isExternal })
    if (isOpen || isExternal) {
      rootStore.openModal()
    }
  }, [practiceId, locationId, partnerId, isOpen, partnerMemberId, rootStore, isExternal])


  const toggleModal = () => {
    toggleModalCallback(false)
    if (rootStore.isOpen) {
      rootStore.closeModal()
    } else {
      rootStore.openModal()
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <StoreContext.Provider value={rootStore}>
        <Dialog
          id="ScheduleWidgetDialog"
          open={rootStore.isOpen}
          onClose={staticPage ? null : toggleModal}
          fullScreen={fullScreen}
          maxWidth="sm"
          classes={classes}
        >
          <DialogTitle onClose={staticPage ? null : toggleModal}>
            <img
              id="ScheduleWidgetLogo"
              src={images.fullLogo}
              height={40}
              style={{ marginLeft: theme.spacing(1) }}
              alt="ScheduleWidget Logo"
            />
          </DialogTitle>
          <DialogContent>
            <WidgetRoutes history={history} toggleModal={toggleModal} staticPage={staticPage} />
          </DialogContent>
          <DialogActions>
            <div id="CopyrightFooter">
              <Typography variant="caption">
                Copyright &copy; ScheduleWidget {new Date().getFullYear()}{' '}
              </Typography>
              <Typography variant="caption">
                <a
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                &nbsp;|&nbsp;
                <a
                  href={TERMS_CONDITIONS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                </a>
              </Typography>
            </div>
          </DialogActions>
        </Dialog>
      </StoreContext.Provider>
    </MuiThemeProvider>
  )
}

WidgetModal.propTypes = {
  partnerId: PropTypes.string,
  practiceId: PropTypes.string,
  locationId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleModalCallback: PropTypes.func,
  partnerMemberId: PropTypes.string,
  isExternal: PropTypes.bool
}

WidgetModal.defaultProps = {
  rootStore: widgetStore
}
export default observer(WidgetModal)
