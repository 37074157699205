/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AppointmentTypeModel } from "./AppointmentTypeModel"
import { AppointmentTypeModelSelector } from "./AppointmentTypeModel.base"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { PartnerModel } from "./PartnerModel"
import { PartnerModelSelector } from "./PartnerModel.base"
import { ProviderModel } from "./ProviderModel"
import { ProviderModelSelector } from "./ProviderModel.base"
import { UserModel } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"


/**
 * AppointmentBase
 * auto generated base class for the model AppointmentModel.
 *
 * An Appointment
 */
export const AppointmentModelBase = ModelBase
  .named('Appointment')
  .props({
    __typename: types.optional(types.literal("Appointment"), "Appointment"),
    appointmentType: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => AppointmentTypeModel))),
    bookedAt: types.union(types.undefined, types.null, types.string),
    end: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    location: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => LocationModel))),
    partner: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PartnerModel))),
    provider: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => ProviderModel))),
    referredBy: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => UserModel))),
    referrer: types.union(types.undefined, types.null, types.string),
    start: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class AppointmentModelSelector extends QueryBuilder {
  get bookedAt() { return this.__attr(`bookedAt`) }
  get end() { return this.__attr(`end`) }
  get id() { return this.__attr(`id`) }
  get referrer() { return this.__attr(`referrer`) }
  get start() { return this.__attr(`start`) }
  appointmentType(builder) { return this.__child(`appointmentType`, AppointmentTypeModelSelector, builder) }
  location(builder) { return this.__child(`location`, LocationModelSelector, builder) }
  partner(builder) { return this.__child(`partner`, PartnerModelSelector, builder) }
  provider(builder) { return this.__child(`provider`, ProviderModelSelector, builder) }
  referredBy(builder) { return this.__child(`referredBy`, UserModelSelector, builder) }
}
export function selectFromAppointment() {
  return new AppointmentModelSelector()
}

export const appointmentModelPrimitives = selectFromAppointment().bookedAt.end.referrer.start
