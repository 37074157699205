import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const Error = ({ message }) => {
  return (
    <Grid container justify="center">
      <Typography variant="button">{message}</Typography>
    </Grid>
  )
}

Error.propTypes = {
  message: PropTypes.string,
}

export default Error
