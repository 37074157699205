import { RootQueryTypeModelBase } from './RootQueryTypeModel.base'

/* A graphql query fragment builders for RootQueryTypeModel */
export {
  selectFromRootQueryType,
  rootQueryTypeModelPrimitives,
  RootQueryTypeModelSelector,
} from './RootQueryTypeModel.base'

/**
 * RootQueryTypeModel
 */
export const RootQueryTypeModel = RootQueryTypeModelBase
