/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { SpecialtyModel } from "./SpecialtyModel"
import { SpecialtyModelSelector } from "./SpecialtyModel.base"


/**
 * ProviderBase
 * auto generated base class for the model ProviderModel.
 *
 * A Provider
 */
export const ProviderModelBase = ModelBase
  .named('Provider')
  .props({
    __typename: types.optional(types.literal("Provider"), "Provider"),
    bio: types.union(types.undefined, types.null, types.string),
    chewsiIsActive: types.union(types.undefined, types.null, types.boolean),
    id: types.identifier,
    name: types.union(types.undefined, types.null, types.string),
    newCustomerFormLink: types.union(types.undefined, types.null, types.string),
    specialties: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late(() => SpecialtyModel)))),
    websiteLink: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class ProviderModelSelector extends QueryBuilder {
  get bio() { return this.__attr(`bio`) }
  get chewsiIsActive() { return this.__attr(`chewsiIsActive`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get newCustomerFormLink() { return this.__attr(`newCustomerFormLink`) }
  get websiteLink() { return this.__attr(`websiteLink`) }
  specialties(builder) { return this.__child(`specialties`, SpecialtyModelSelector, builder) }
}
export function selectFromProvider() {
  return new ProviderModelSelector()
}

export const providerModelPrimitives = selectFromProvider().bio.chewsiIsActive.name.newCustomerFormLink.websiteLink
