import * as Sentry from '@sentry/browser'
import qs from 'qs'
import React from 'react'
import ReactDOM from 'react-dom'
import 'mobx-react-lite/batchingForReactDom'

import App from './lib/components/App'
import { APP_VERSION, SENTRY_DSN, SENTRY_ENVIRONMENT } from './lib/constants'
import './index.css'
import { isDev } from './lib/utils'

const rootElement = document.getElementById('ows-widget')
const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
const practiceId = params.practice_id || process.env.REACT_APP_PRACTICE_ID
const isDark = Boolean(params.dark)
const ctaText = params.btn_text
const position = params.position === 'right' ? 'right' : 'left'
const partnerId = params.partner_id
const partnerMemberId = params.partner_member_id
const isExternal = params.is_external === 'true'

if (!isDev) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: APP_VERSION,
    environment: SENTRY_ENVIRONMENT,
  })
  Sentry.configureScope(scope => {
    scope.setTag('origin', document.referrer)
  })
}

ReactDOM.render(
  <App
    isDark={isDark}
    ctaText={ctaText}
    position={position}
    practiceId={practiceId}
    partnerId={partnerId}
    partnerMemberId={partnerMemberId}
    isExternal={isExternal}
  />,
  rootElement
)
