import { API_URL } from './config'

export const DEFAULT_CTA_TEXT = 'New patients & emergencies book now!'
export const PRIVACY_POLICY_URL = `${API_URL}/privacy-policy`
export const TERMS_CONDITIONS_URL = `${API_URL}/terms-conditions`
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const APP_VERSION = process.env.REACT_APP_VERSION
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const GA_PROPERTY_ID = process.env.REACT_APP_GA_PROPERTY_ID
export const DEBUG_GA = process.env.REACT_APP_DEBUG_GA
export const YAPI_PARTNER_ID = process.env.REACT_APP_YAPI_PARTNER_ID
