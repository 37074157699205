/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AppointmentTypeModel } from "./AppointmentTypeModel"
import { AppointmentTypeModelSelector } from "./AppointmentTypeModel.base"
import { FindAProviderModel } from "./FindAProviderModel"
import { FindAProviderModelSelector } from "./FindAProviderModel.base"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { NotificationModel } from "./NotificationModel"
import { NotificationModelSelector } from "./NotificationModel.base"
import { PartnerModel } from "./PartnerModel"
import { PartnerModelSelector } from "./PartnerModel.base"
import { PatientModel } from "./PatientModel"
import { PatientModelSelector } from "./PatientModel.base"
import { PracticeAppointmentAnalyticsModel } from "./PracticeAppointmentAnalyticsModel"
import { PracticeAppointmentAnalyticsModelSelector } from "./PracticeAppointmentAnalyticsModel.base"
import { PracticeModel } from "./PracticeModel"
import { PracticeModelSelector } from "./PracticeModel.base"
import { ProviderAppointmentAnalyticsModel } from "./ProviderAppointmentAnalyticsModel"
import { ProviderAppointmentAnalyticsModelSelector } from "./ProviderAppointmentAnalyticsModel.base"
import { SpecialtyModel } from "./SpecialtyModel"
import { SpecialtyModelSelector } from "./SpecialtyModel.base"


/**
 * RootQueryTypeBase
 * auto generated base class for the model RootQueryTypeModel.
 */
export const RootQueryTypeModelBase = ModelBase
  .named('RootQueryType')
  .props({
    __typename: types.optional(types.literal("RootQueryType"), "RootQueryType"),
    /** List appointment types for a practice */
    appointmentTypes: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => AppointmentTypeModel))))),
    /** Find a provider */
    findAProvider: types.union(types.undefined, types.null, types.late(() => FindAProviderModel)),
    /** Get a location */
    location: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => LocationModel))),
    locations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => LocationModel))))),
    /** List notifications for authenticated user's practice. Defaults to unread. */
    notifications: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => NotificationModel))))),
    /** Get a partner */
    partner: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PartnerModel))),
    /** List all partners */
    partners: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PartnerModel))))),
    /** Get a patient */
    patient: types.union(types.undefined, types.null, types.late(() => PatientModel)),
    /** Get a practice */
    practice: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PracticeModel))),
    /** List appointment analytics for all practices */
    practiceAppointmentAnalytics: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PracticeAppointmentAnalyticsModel))))),
    /** Get all Practices */
    practices: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PracticeModel))))),
    /** List appointment analytics for all providers */
    providerAppointmentAnalytics: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => ProviderAppointmentAnalyticsModel))))),
    /** List specialties */
    specialties: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late(() => SpecialtyModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class RootQueryTypeModelSelector extends QueryBuilder {
  appointmentTypes(builder) { return this.__child(`appointmentTypes`, AppointmentTypeModelSelector, builder) }
  findAProvider(builder) { return this.__child(`findAProvider`, FindAProviderModelSelector, builder) }
  location(builder) { return this.__child(`location`, LocationModelSelector, builder) }
  locations(builder) { return this.__child(`locations`, LocationModelSelector, builder) }
  notifications(builder) { return this.__child(`notifications`, NotificationModelSelector, builder) }
  partner(builder) { return this.__child(`partner`, PartnerModelSelector, builder) }
  partners(builder) { return this.__child(`partners`, PartnerModelSelector, builder) }
  patient(builder) { return this.__child(`patient`, PatientModelSelector, builder) }
  practice(builder) { return this.__child(`practice`, PracticeModelSelector, builder) }
  practiceAppointmentAnalytics(builder) { return this.__child(`practiceAppointmentAnalytics`, PracticeAppointmentAnalyticsModelSelector, builder) }
  practices(builder) { return this.__child(`practices`, PracticeModelSelector, builder) }
  providerAppointmentAnalytics(builder) { return this.__child(`providerAppointmentAnalytics`, ProviderAppointmentAnalyticsModelSelector, builder) }
  specialties(builder) { return this.__child(`specialties`, SpecialtyModelSelector, builder) }
}
export function selectFromRootQueryType() {
  return new RootQueryTypeModelSelector()
}

export const rootQueryTypeModelPrimitives = selectFromRootQueryType()
