import gql from 'graphql-tag'

export const PRACTICE = gql`
query PracticeQuery($id: ID!) {
  practice(id: $id) {
    __typename
    id
    name
    locations {
      __typename
      id
      name
      street
      city
      state
      zip
      phoneNumber
      timezone
      yapiBookingUrl
      yapiIntegrationEnabled
      providers {
        __typename
        id
        name
        chewsiIsActive
      }
    }
    partners {
      __typename
      id
      gaTrackingCode
      partner {
        __typename
        id
        name
      }
    }
  }
}
`
