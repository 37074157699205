import React from 'react'
import { observer } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import { Formik, Field } from 'formik'
import { TextField, Select, RadioGroup } from 'formik-material-ui'
import * as Yup from 'yup'

import * as Utils from '../utils'
import { useQuery } from '../models/reactUtils'
import AppointmentDetails from './AppointmentDetails'
import PhoneNumberField from './PhoneNumberField'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  errorIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  email: {
    color: 'white',
  },
}))

const PatientFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  dob: Yup.date()
    .required('Date of birth is required')
    .max(new Date(), 'Birthdate must be in the past'),
  email: Yup.string().email().required('Email address is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .min(14, 'Phone number must be at least 10 digits'),
  comment: Yup.string().max(500),
})

export const PatientForm = () => {
  const { store, setQuery } = useQuery()
  const classes = useStyles()
  const [labelWidth, setLabelWidth] = React.useState(0)
  const inputLabel = React.useCallback(node => {
    if (node !== null) {
      setLabelWidth(node.offsetWidth)
    }
  }, [])

  const location = store.selectedLocation

  return (
    <React.Fragment>
      <Grid justify="flex-start" container>
        <Button
          onClick={
            store.requestAppointment
              ? store.resetRequestAppointment
              : store.resetSelectedAppointment
          }
          variant="text"
        >
          <KeyboardArrowLeft /> Back
        </Button>
      </Grid>
      <AppointmentDetails />
      <Divider />
      <Typography
        id="ContactInfoHeader"
        gutterBottom
        align="center"
        variant="h6"
      >
        Contact info
      </Typography>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          dob: '',
          email: '',
          phone: '',
          comment: '',
          providerId: '',
          isEmergency: '',
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            store.toggleSubmitting(true)
            if (store.requestAppointment) {
              const query = store.submitRequestAppointment(values)
              setQuery(query)
              await query
              store.redirectToConfirm()
            } else {
              const { isEmergency, providerId, ...patientFields } = values
              const query = store.submitBookAppointment(patientFields)
              setQuery(query)
              await query
              store.toggleSubmitting(false)
              setSubmitting(false)
              store.redirectToConfirm()
            }
          } catch (queryError) {
            setSubmitting(false)
            store.toggleSubmitting(false)
            Utils.mapErrorsToFields(queryError, store, setErrors)
          }
        }}
        validationSchema={PatientFormSchema}
      >
        {({ values, isSubmitting, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit} data-testid="form">
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  name="firstName"
                  variant="outlined"
                  required
                  id="firstName"
                  label="First Name"
                  autoFocus
                  fullWidth
                  value={values.firstName}
                  onChange={handleChange}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="lastName"
                  variant="outlined"
                  required
                  id="lastName"
                  label="Last Name"
                  fullWidth
                  value={values.lastName}
                  onChange={handleChange}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="dob"
                  variant="outlined"
                  required
                  id="date"
                  label="Birthdate"
                  type="date"
                  fullWidth
                  value={values.dob}
                  onChange={handleChange}
                  component={TextField}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  required
                  fullWidth
                  label="Phone number"
                  id="phone"
                  name="phone"
                  type="tel"
                  value={values.phone}
                  onChange={handleChange}
                  component={PhoneNumberField}
                />
              </Grid>
              {store.requestAppointment ? (
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="providerId" ref={inputLabel} shrink>
                      Select a provider you would like to see
                    </InputLabel>
                    <Field
                      required
                      name="providerId"
                      variant="outlined"
                      component={Select}
                      onChange={handleChange}
                      value={values.providerId}
                      displayEmpty
                      data-testid="providerSelect"
                      input={
                        <OutlinedInput
                          id="providerId"
                          labelWidth={labelWidth}
                          name="providerId"
                          notched
                        />
                      }
                    >
                      <MenuItem value="">First available</MenuItem>
                      {location &&
                        location.activeProviders &&
                        location.activeProviders.map(provider => (
                          <MenuItem key={provider.id} value={provider.id}>
                            {provider.name}
                          </MenuItem>
                        ))}
                    </Field>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  fullWidth
                  id="comment"
                  label="Comment (optional)"
                  name="comment"
                  type="text"
                  rows={4}
                  helperText="Do not include any protected health information in the
                  comment field. You can provide additional details when contacted directly
                  by the practice."
                  value={values.comment}
                  onChange={handleChange}
                  component={TextField}
                />
              </Grid>
              {store.requestAppointment ? (
                <Grid item xs={12}>
                  <FormLabel>Is this a dental emergency?</FormLabel>
                  {values.isEmergency && (
                    <FormHelperText id="IsEmergencyHelperText" error>
                      If you are having difficulty breathing, call 911
                      immediately.
                    </FormHelperText>
                  )}
                  <Field
                    component={RadioGroup}
                    row
                    name="isEmergency"
                    value={values.isEmergency}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio id="isEmergency" disabled={isSubmitting} />
                      }
                      label="Yes"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value=""
                      control={<Radio disabled={isSubmitting} />}
                      label="No"
                      disabled={isSubmitting}
                    />
                  </Field>
                </Grid>
              ) : null}
            </Grid>
            <Button
              id="RequestAppointmentButton"
              data-testid="SubmitFormButton"
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isValid || isSubmitting}
            >
              {store.requestAppointment
                ? 'Request appointment'
                : 'Book appointment'}
            </Button>
          </form>
        )}
      </Formik>
    </React.Fragment>
  )
}

PatientForm.propTypes = {}

export default observer(PatientForm)
