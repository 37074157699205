import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import AppointmentGroup from './AppointmentGroup'
import { useQuery } from '../models/reactUtils'
import Loading from './Loading'
import Error from './Error'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const AppointmentList = observer(() => {
  const classes = useStyles()
  const { store, loading, error } = useQuery(rootStore =>
    rootStore.fetchAppointmentsForLocation(rootStore.selectedLocationId)
  )

  // eslint-disable-next-line prefer-destructuring
  const selectedLocationId = store.selectedLocationId

  React.useEffect(() => {
    store.fetchAppointmentsForLocation(selectedLocationId)
  }, [store, selectedLocationId])

  if (error) {
    return (
      <Error message="Sorry, there was a problem loading available appointments" />
    )
  }
  if (loading) {
    return <Loading message="Please wait while we load listed appointments" />
  }
  if (store.groupedAndSortedAppointments.length < 1) {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Button
          id="RequestAppointmentButton"
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={store.setRequestAppointment}
        >
          Request an appointment
        </Button>
      </Grid>
    )
  }
  return (
    <div>
      <Typography variant="h6">Available appointments</Typography>
      <Typography variant="caption" gutterBottom>
        Click on any of the time slots below to book your appointment.
      </Typography>
      <Divider />
      <React.Fragment>
        {store.groupedAndSortedAppointments.map(appointmentGroup => (
          <AppointmentGroup
            key={appointmentGroup.date}
            appointmentGroup={appointmentGroup}
            selectAppointment={store.selectAppointment}
          />
        ))}
        <Grid container justify="center" alignItems="center" direction="column">
          <Typography variant="subtitle1">
            Can&apos;t find a time that works for you?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => store.setRequestAppointment()}
          >
            Request an appointment
          </Button>
        </Grid>
      </React.Fragment>
    </div>
  )
})

AppointmentList.displayName = 'AppointmentList'

export default AppointmentList
