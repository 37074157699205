/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
import { types } from "mobx-state-tree"
import { MSTGQLStore, configureStoreMixin } from "mst-gql"

import { RootQueryTypeModel } from "./RootQueryTypeModel"
import { rootQueryTypeModelPrimitives, RootQueryTypeModelSelector } from "./RootQueryTypeModel.base"
import { AppointmentTypeModel } from "./AppointmentTypeModel"
import { appointmentTypeModelPrimitives, AppointmentTypeModelSelector } from "./AppointmentTypeModel.base"
import { FindAProviderModel } from "./FindAProviderModel"
import { findAProviderModelPrimitives, FindAProviderModelSelector } from "./FindAProviderModel.base"
import { LocationModel } from "./LocationModel"
import { locationModelPrimitives, LocationModelSelector } from "./LocationModel.base"
import { AppointmentModel } from "./AppointmentModel"
import { appointmentModelPrimitives, AppointmentModelSelector } from "./AppointmentModel.base"
import { PartnerModel } from "./PartnerModel"
import { partnerModelPrimitives, PartnerModelSelector } from "./PartnerModel.base"
import { FindAProviderConfigModel } from "./FindAProviderConfigModel"
import { findAProviderConfigModelPrimitives, FindAProviderConfigModelSelector } from "./FindAProviderConfigModel.base"
import { ProviderModel } from "./ProviderModel"
import { providerModelPrimitives, ProviderModelSelector } from "./ProviderModel.base"
import { SpecialtyModel } from "./SpecialtyModel"
import { specialtyModelPrimitives, SpecialtyModelSelector } from "./SpecialtyModel.base"
import { UserModel } from "./UserModel"
import { userModelPrimitives, UserModelSelector } from "./UserModel.base"
import { PracticeModel } from "./PracticeModel"
import { practiceModelPrimitives, PracticeModelSelector } from "./PracticeModel.base"
import { PartnerPracticeModel } from "./PartnerPracticeModel"
import { partnerPracticeModelPrimitives, PartnerPracticeModelSelector } from "./PartnerPracticeModel.base"
import { FeatureModel } from "./FeatureModel"
import { featureModelPrimitives, FeatureModelSelector } from "./FeatureModel.base"
import { MetaModel } from "./MetaModel"
import { metaModelPrimitives, MetaModelSelector } from "./MetaModel.base"
import { SearchCoordsModel } from "./SearchCoordsModel"
import { searchCoordsModelPrimitives, SearchCoordsModelSelector } from "./SearchCoordsModel.base"
import { NotificationModel } from "./NotificationModel"
import { notificationModelPrimitives, NotificationModelSelector } from "./NotificationModel.base"
import { AppointmentRequestModel } from "./AppointmentRequestModel"
import { appointmentRequestModelPrimitives, AppointmentRequestModelSelector } from "./AppointmentRequestModel.base"
import { PatientModel } from "./PatientModel"
import { patientModelPrimitives, PatientModelSelector } from "./PatientModel.base"
import { PracticeAppointmentAnalyticsModel } from "./PracticeAppointmentAnalyticsModel"
import { practiceAppointmentAnalyticsModelPrimitives, PracticeAppointmentAnalyticsModelSelector } from "./PracticeAppointmentAnalyticsModel.base"
import { ProviderAppointmentAnalyticsModel } from "./ProviderAppointmentAnalyticsModel"
import { providerAppointmentAnalyticsModelPrimitives, ProviderAppointmentAnalyticsModelSelector } from "./ProviderAppointmentAnalyticsModel.base"
import { RootMutationTypeModel } from "./RootMutationTypeModel"
import { rootMutationTypeModelPrimitives, RootMutationTypeModelSelector } from "./RootMutationTypeModel.base"






/**
* Store, managing, among others, all the objects received through graphQL
*/
export const RootStoreBase = MSTGQLStore
  .named("RootStore")
  .extend(configureStoreMixin([['RootQueryType', () => RootQueryTypeModel], ['AppointmentType', () => AppointmentTypeModel], ['FindAProvider', () => FindAProviderModel], ['Location', () => LocationModel], ['Appointment', () => AppointmentModel], ['Partner', () => PartnerModel], ['FindAProviderConfig', () => FindAProviderConfigModel], ['Provider', () => ProviderModel], ['Specialty', () => SpecialtyModel], ['User', () => UserModel], ['Practice', () => PracticeModel], ['PartnerPractice', () => PartnerPracticeModel], ['Feature', () => FeatureModel], ['Meta', () => MetaModel], ['SearchCoords', () => SearchCoordsModel], ['Notification', () => NotificationModel], ['AppointmentRequest', () => AppointmentRequestModel], ['Patient', () => PatientModel], ['PracticeAppointmentAnalytics', () => PracticeAppointmentAnalyticsModel], ['ProviderAppointmentAnalytics', () => ProviderAppointmentAnalyticsModel], ['RootMutationType', () => RootMutationTypeModel]], ['AppointmentType', 'Location', 'Appointment', 'Partner', 'Provider', 'User', 'Practice', 'PartnerPractice', 'Notification', 'AppointmentRequest', 'PracticeAppointmentAnalytics', 'ProviderAppointmentAnalytics'], "js"))
  .props({
    appointmentTypes: types.optional(types.map(types.late(() => AppointmentTypeModel)), {}),
    locations: types.optional(types.map(types.late(() => LocationModel)), {}),
    appointments: types.optional(types.map(types.late(() => AppointmentModel)), {}),
    partners: types.optional(types.map(types.late(() => PartnerModel)), {}),
    providers: types.optional(types.map(types.late(() => ProviderModel)), {}),
    users: types.optional(types.map(types.late(() => UserModel)), {}),
    practices: types.optional(types.map(types.late(() => PracticeModel)), {}),
    partnerPractices: types.optional(types.map(types.late(() => PartnerPracticeModel)), {}),
    notifications: types.optional(types.map(types.late(() => NotificationModel)), {}),
    appointmentRequests: types.optional(types.map(types.late(() => AppointmentRequestModel)), {}),
    practiceAppointmentAnalytics: types.optional(types.map(types.late(() => PracticeAppointmentAnalyticsModel)), {}),
    providerAppointmentAnalytics: types.optional(types.map(types.late(() => ProviderAppointmentAnalyticsModel)), {})
  })
  .actions(self => ({
    // List appointment types for a practice
    queryAppointmentTypes(variables, resultSelector = appointmentTypeModelPrimitives.toString(), options = {}) {
      return self.query(`query appointmentTypes { appointmentTypes {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentTypeModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Find a provider
    queryFindAProvider(variables, resultSelector = findAProviderModelPrimitives.toString(), options = {}) {
      return self.query(`query findAProvider($address: String!, $apptDate: Date, $apptTime: Time, $partnerId: ID, $radiusInMiles: Int, $specialty: String) { findAProvider(address: $address, apptDate: $apptDate, apptTime: $apptTime, partnerId: $partnerId, radiusInMiles: $radiusInMiles, specialty: $specialty) {
        ${typeof resultSelector === "function" ? resultSelector(new FindAProviderModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get a location
    queryLocation(variables, resultSelector = locationModelPrimitives.toString(), options = {}) {
      return self.query(`query location($id: ID!) { location(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new LocationModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryLocations(variables, resultSelector = locationModelPrimitives.toString(), options = {}) {
      return self.query(`query locations { locations {
        ${typeof resultSelector === "function" ? resultSelector(new LocationModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List notifications for authenticated user's practice. Defaults to unread.
    queryNotifications(variables, resultSelector = notificationModelPrimitives.toString(), options = {}) {
      return self.query(`query notifications($status: NotificationStatus) { notifications(status: $status) {
        ${typeof resultSelector === "function" ? resultSelector(new NotificationModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get a partner
    queryPartner(variables, resultSelector = partnerModelPrimitives.toString(), options = {}) {
      return self.query(`query partner($id: ID!) { partner(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new PartnerModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List all partners
    queryPartners(variables, resultSelector = partnerModelPrimitives.toString(), options = {}) {
      return self.query(`query partners { partners {
        ${typeof resultSelector === "function" ? resultSelector(new PartnerModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get a patient
    queryPatient(variables, resultSelector = patientModelPrimitives.toString(), options = {}) {
      return self.query(`query patient($id: ID!, $source: PatientSource!) { patient(id: $id, source: $source) {
        ${typeof resultSelector === "function" ? resultSelector(new PatientModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get a practice
    queryPractice(variables, resultSelector = practiceModelPrimitives.toString(), options = {}) {
      return self.query(`query practice($id: ID!) { practice(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new PracticeModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List appointment analytics for all practices
    queryPracticeAppointmentAnalytics(variables, resultSelector = practiceAppointmentAnalyticsModelPrimitives.toString(), options = {}) {
      return self.query(`query practiceAppointmentAnalytics($fromDate: Date!, $toDate: Date!) { practiceAppointmentAnalytics(fromDate: $fromDate, toDate: $toDate) {
        ${typeof resultSelector === "function" ? resultSelector(new PracticeAppointmentAnalyticsModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get all Practices
    queryPractices(variables, resultSelector = practiceModelPrimitives.toString(), options = {}) {
      return self.query(`query practices { practices {
        ${typeof resultSelector === "function" ? resultSelector(new PracticeModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List appointment analytics for all providers
    queryProviderAppointmentAnalytics(variables, resultSelector = providerAppointmentAnalyticsModelPrimitives.toString(), options = {}) {
      return self.query(`query providerAppointmentAnalytics($fromDate: Date!, $toDate: Date!) { providerAppointmentAnalytics(fromDate: $fromDate, toDate: $toDate) {
        ${typeof resultSelector === "function" ? resultSelector(new ProviderAppointmentAnalyticsModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List specialties
    querySpecialties(variables, resultSelector = specialtyModelPrimitives.toString(), options = {}) {
      return self.query(`query specialties { specialties {
        ${typeof resultSelector === "function" ? resultSelector(new SpecialtyModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Book an appointment
    mutateBookAppointment(variables, resultSelector = appointmentModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation bookAppointment($appointmentId: ID!, $locationId: ID, $partnerId: ID, $patient: PatientInput!, $providerId: ID, $referrer: String) { bookAppointment(appointmentId: $appointmentId, locationId: $locationId, partnerId: $partnerId, patient: $patient, providerId: $providerId, referrer: $referrer) {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Create an appointment
    mutateCreateAppointment(variables, resultSelector = appointmentModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation createAppointment($appointmentTypeId: ID, $end: DateTime!, $locationId: ID!, $providerId: ID!, $start: DateTime!) { createAppointment(appointmentTypeId: $appointmentTypeId, end: $end, locationId: $locationId, providerId: $providerId, start: $start) {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Request an appointment
    mutateRequestAppointment(variables, resultSelector = appointmentRequestModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation requestAppointment($isEmergency: Boolean, $locationId: ID!, $partnerId: ID, $patient: PatientInput!, $providerId: ID, $referrer: String, $yapiRequest: Boolean) { requestAppointment(isEmergency: $isEmergency, locationId: $locationId, partnerId: $partnerId, patient: $patient, providerId: $providerId, referrer: $referrer, yapiRequest: $yapiRequest) {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentRequestModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Update an appointment
    mutateUpdateAppointment(variables, resultSelector = appointmentModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation updateAppointment($appointmentTypeId: ID, $end: DateTime, $id: ID!, $locationId: ID, $providerId: ID, $start: DateTime) { updateAppointment(appointmentTypeId: $appointmentTypeId, end: $end, id: $id, locationId: $locationId, providerId: $providerId, start: $start) {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
  }))
