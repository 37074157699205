import moment from 'moment-timezone'
import { getRoot } from 'mobx-state-tree'
import { AppointmentModelBase } from './AppointmentModel.base'

/* A graphql query fragment builders for AppointmentModel */
export {
  selectFromAppointment,
  appointmentModelPrimitives,
  AppointmentModelSelector,
} from './AppointmentModel.base'

/**
 * AppointmentModel
 *
 * An Appointment
 */
export const AppointmentModel = AppointmentModelBase.views(self => ({
  get root() {
    return getRoot(self)
  },
  get startDateTime() {
    return moment.utc(self.start).tz(self.location.timezone)
  },
  get endDateTime() {
    return moment.utc(self.end).tz(self.location.timezone)
  },
  get startDateTimeString() {
    return self.startDateTime.format('YYYYMMDDTHHmmss')
  },
  get endDateTimeString() {
    return self.endDateTime.format('YYYYMMDDTHHmmss')
  },
  get appointmentDuration() {
    return moment
      .duration(self.endDateTime.diff(self.startDateTime))
      .asHours()
      .toString()
  },
  get addToCalendarEventData() {
    const { practice } = self.root
    return {
      title: `Dental appointment with ${practice.name}`,
      description: 'Your dental appointment',
      location: self.location.formattedAddress,
      startDatetime: self.startDateTimeString,
      endDatetime: self.endDateTimeString,
      duration: self.appointmentDuration,
      timezone: self.location.timezone,
    }
  },
}))
