import React from 'react'
import PropTypes from 'prop-types'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const DialogTitle = ({ classes, onClose, children }) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    closeButton: PropTypes.string,
  }),
  onClose: PropTypes.func,
  children: PropTypes.node,
}
export default withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: 'auto',
    color: theme.palette.common.white,
  },
  subtitle1: {
    marginRight: 40,
    lineHeight: 2,
    color: theme.palette.common.white,
  },
  avatar: {
    maxHeight: 40,
    marginLeft: theme.spacing(1),
  },
}))(DialogTitle)
