import { PartnerModelBase } from './PartnerModel.base'

/* A graphql query fragment builders for PartnerModel */
export {
  selectFromPartner,
  partnerModelPrimitives,
  PartnerModelSelector,
} from './PartnerModel.base'

/**
 * PartnerModel
 *
 * A Partner object that describes a partner entity
 */
export const PartnerModel = PartnerModelBase
  .views(self => ({
    get trackerName() {
      return self.name.replace(/\W/g, "")
    }
  }))
