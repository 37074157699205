import { PatientModelBase } from "./PatientModel.base"


/* A graphql query fragment builders for PatientModel */
export { selectFromPatient, patientModelPrimitives, PatientModelSelector } from "./PatientModel.base"

/**
 * PatientModel
 *
 * A Patient
 */
export const PatientModel = PatientModelBase
  .views(self => ({
    get yapiUrlParams() {
      return {
        firstName: self.firstName,
        lastName: self.lastName,
        email: self.email,
        mobileNumber: self.phone
      }
    }
  }))
