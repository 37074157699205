import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiDialogActions from '@material-ui/core/DialogActions'

export default withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))(props => {
  const { children, classes } = props
  return (
    <MuiDialogActions className={classes.root}>{children}</MuiDialogActions>
  )
})
