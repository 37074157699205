/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { MetaModel } from "./MetaModel"
import { MetaModelSelector } from "./MetaModel.base"


/**
 * FindAProviderBase
 * auto generated base class for the model FindAProviderModel.
 *
 * Find-a-provider
 */
export const FindAProviderModelBase = ModelBase
  .named('FindAProvider')
  .props({
    __typename: types.optional(types.literal("FindAProvider"), "FindAProvider"),
    locations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => LocationModel))))),
    meta: types.union(types.undefined, types.null, types.late(() => MetaModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class FindAProviderModelSelector extends QueryBuilder {
  locations(builder) { return this.__child(`locations`, LocationModelSelector, builder) }
  meta(builder) { return this.__child(`meta`, MetaModelSelector, builder) }
}
export function selectFromFindAProvider() {
  return new FindAProviderModelSelector()
}

export const findAProviderModelPrimitives = selectFromFindAProvider()
