import React from 'react'
import PropTypes from 'prop-types'
import AddToCalendarHOC from 'react-add-to-calendar-hoc'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import EventIcon from '@material-ui/icons/Event'

const DropDownMenu = ({ isOpen, onRequestClose, children, ...rest }) => {
  return (
    <Dialog open={isOpen} onClose={onRequestClose} {...rest}>
      <DialogTitle>Add to calendar</DialogTitle>
      <List>
        {children.map(item => (
          <ListItem key={item.key}>{item}</ListItem>
        ))}
        <ListItem divider />
        <ListItem alignItems="center" button onClick={onRequestClose}>
          <Typography variant="button" align="center" style={{ width: '100%' }}>
            Close
          </Typography>
        </ListItem>
      </List>
    </Dialog>
  )
}
DropDownMenu.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.any,
}

const CalendarButton = ({ onClick, children, ...rest }) => {
  return (
    <Button onClick={onClick} {...rest}>
      <EventIcon style={{ marginRight: 8 }} /> {children}
    </Button>
  )
}
CalendarButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string,
}

const AddToCalendar = AddToCalendarHOC(CalendarButton, DropDownMenu)

export default AddToCalendar
