import { AppointmentRequestModelBase } from './AppointmentRequestModel.base'

/* A graphql query fragment builders for AppointmentRequestModel */
export {
  selectFromAppointmentRequest,
  appointmentRequestModelPrimitives,
  AppointmentRequestModelSelector,
} from './AppointmentRequestModel.base'

/**
 * AppointmentRequestModel
 *
 * An Appointment Request
 */
export const AppointmentRequestModel = AppointmentRequestModelBase
