import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from '../models/reactUtils'
import AddToCalendar from './AddToCalendar'
import AppointmentDetails from './AppointmentDetails'


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary[500],
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    height: '100%',
  },
  caption: {
    color: grey[500],
    marginTop: theme.spacing(3),
  },
  links: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontFamily: theme.typography.fontFamily,
  },
  doneButton: {
    marginTop: theme.spacing(1),
    width: '50%',
  },
}))

export const Confirm = ({ toggleModalCallback, staticPage }) => {
  const classes = useStyles()
  const { store } = useQuery()
  const { practice, appointment } = store
  return (
    <Grid container direction="column" justify="center">
      <Typography id="AppointmentRequestConfirmationHeader" variant="h5" align="center">
        {store.requestAppointment
          ? 'Your appointment has been requested!'
          : 'Your appointment has been booked!'}
      </Typography>
      <Divider className={classes.divider} />
      <AppointmentDetails />
      {store.requestAppointment ? null : (
        <Grid container justify="center">
          <AddToCalendar
            linkProps={{
              className: classes.links,
            }}
            buttonProps={{ variant: 'contained', color: 'primary' }}
            event={appointment.addToCalendarEventData}
          />
        </Grid>
      )}
      <Divider className={classes.divider} />
      <Typography>
        <strong>{practice.name}</strong> will contact you in order to confirm
        your appointment.
      </Typography>
      {store.requestAppointment
          ? ''
          : (<Typography>
            You will also receive an email with your appointment details in a few
            moments.
          </Typography>
      )}
      <Typography variant="caption" className={classes.caption}>
        If you do not receive the confirmation email within a few minutes,
        please check your Junk/Spam folder just in case the confirmation email
        got delivered there instead of your inbox. If so, select the
        confirmation message and click Not Junk, which will allow future
        emails to get through.
      </Typography>
      <Grid container justify="center">
        {staticPage ? 
        (<Typography>You may close this window when you're done.</Typography>)
        :
        (<Button
          id="DoneButton"
          className={classes.doneButton}
          variant="contained"
          color="primary"
          onClick={toggleModalCallback}
        >
          Done
        </Button>)
      }
      </Grid>
    </Grid>
  )
}

Confirm.propTypes = {
  toggleModalCallback: PropTypes.func,
  staticPage: PropTypes.bool
}

Confirm.defaultProps = {
  toggleModalCallback: () => {},
  staticPage: false,
}
export default observer(Confirm)
