import React from 'react'
import moment from 'moment-timezone'
import Typography from '@material-ui/core/Typography'
import { observer } from 'mobx-react-lite'
import { useQuery } from '../models/reactUtils'

const AppointmentDetails = () => {
  const { store } = useQuery()
  const appointment = store.appointments.get(store.selectedAppointment)
  const location = store.locations.get(store.selectedLocationId)
  return (
    <React.Fragment>
      <Typography variant="h6">Appointment details</Typography>
      {store.selectedAppointment ? (
        <React.Fragment>
          <Typography variant="body1">
            <strong>Date:</strong>{' '}
            {moment(appointment.start).format('dddd, MMMM Do, YYYY')}
          </Typography>
          <Typography>
            <strong>Time:</strong>{' '}
            {moment
              .tz(appointment.start, 'UTC')
              .tz(appointment.location.timezone)
              .format('h:mm a z')}
          </Typography>
          <Typography>
            <strong>Provider</strong>: {appointment.provider.name}
          </Typography>
        </React.Fragment>
      ) : null}
      <Typography>
        <strong>Location</strong>: {location.formattedAddress}
      </Typography>
      <Typography>
        <strong>Phone</strong>: {location.formattedPhoneNumber}
      </Typography>
    </React.Fragment>
  )
}

export default observer(AppointmentDetails)
