import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import { fieldToTextField } from 'formik-material-ui'
import { getIn } from 'formik'
import { phoneNumberMask } from '../utils'

const PhoneMaskedInput = props => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={phoneNumberMask}
      showMask
      placeholderChar={'\u2000'} // eslint-disable-line react/jsx-curly-brace-presence
      guide={false}
      keepCharPositions={false}
    />
  )
}

PhoneMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

const PhoneNumberField = props => {
  const [labelWidth, setLabelWidth] = React.useState(0)
  const inputLabel = React.useCallback(node => {
    if (node !== null) {
      setLabelWidth(node.offsetWidth)
    }
  }, [])
  const fieldError = getIn(props.form.errors, props.field.name)
  const showError = getIn(props.form.touched, props.field.name) && !!fieldError
  const errorText = showError ? fieldError : props.helperText
  const { helperText, ...rest } = fieldToTextField(props)
  return (
    <FormControl className={rest.className} fullWidth={rest.fullWidth}>
      <InputLabel
        error={!!errorText}
        htmlFor="phone"
        variant="outlined"
        ref={inputLabel}
        shrink={!!props.value}
        required={rest.required}
      >
        {rest.label}
      </InputLabel>
      <OutlinedInput
        onChange={event => {
          const { value } = event.target
          props.form.setFieldValue(props.field.name, value)
        }}
        {...rest}
        inputComponent={PhoneMaskedInput}
        notched={!!props.value}
        labelWidth={labelWidth}
      />
      {!!errorText && (
        <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
      )}
    </FormControl>
  )
}

PhoneNumberField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
}

export default PhoneNumberField
